import React from "react";
import styles from "./styles.module.scss";
import { SatinLogoVector } from "asset";

const Tag = () => {
	return (
		<>
			<div className={`row2 ${styles.tag}`}>
				<SatinLogoVector />
				<p>100% trusted car rental in lagos</p>
			</div>
		</>
	);
};

export { Tag };
