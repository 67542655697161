import React from "react";
import styles from "./styles.module.scss";
import { Button } from "components";
import {
	BlackWebchatButtonIcon,
	WhatsappButtonIcon,
	WhiteWebchatButtonIcon,
} from "asset";

interface BookingButtonsProps {
	dark?: boolean;
	className?: string;
}

const BookingButtons: React.FC<BookingButtonsProps> = ({ dark, className }) => {
	return (
		<>
			<div className={`row2 ${styles.bookingButtons} ${className}`}>
				<Button
					type={"fill"}
					onClick={() => {}}
					className={`row ${styles.bookingButtons__whatsapp}`}
				>
					<WhatsappButtonIcon />
					<p>request a ride </p>
				</Button>

				{dark ? (
					<Button
						type={"blackOutline"}
						onClick={() => {}}
						className={`row ${styles.bookingButtons__blackWebchat} ${styles.bookingButtons__webchat}`}
					>
						<BlackWebchatButtonIcon />
						<p>request a ride on webchat </p>
					</Button>
				) : (
					<Button
						type={"whiteOutline"}
						onClick={() => {}}
						className={`row ${styles.bookingButtons__whiteWebchat} ${styles.bookingButtons__webchat}`}
					>
						<WhiteWebchatButtonIcon />
						<p>request a ride on webchat </p>
					</Button>
				)}
			</div>
		</>
	);
};

export { BookingButtons };
