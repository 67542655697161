import React from "react";
import styles from "./styles.module.scss";
import { CarSpec, CarSpecProps } from "components/carSpecCard";

export interface CarDetailsProps {
	carImage: any;
	wifi: string;
	driveType: string;
	driverLevel: string;
	vehicleType: string;
	carColor: string;
	carName: string;
}

const CarDetails: React.FC<CarDetailsProps> = ({
	carColor,
	carImage,
	driveType,
	vehicleType,
	driverLevel,
	carName,
	wifi,
}) => {
	const cars: CarSpecProps[] = [
		{
			value: wifi,
			spec: "In Vehicle",
		},
		{
			value: driveType,
			spec: "drive type",
		},
		{
			value: driverLevel,
			spec: "driver level",
		},
		{
			value: vehicleType,
			spec: "vehicle type",
		},
		{
			value: carColor,
			spec: "satin paint type",
		},
	];
	return (
		<>
			<div className={styles.carDetails}>
				<div className={`column0 ${styles.carDetails__info}`}>
					<h1 className={styles.carDetails__info__name}>{carName}</h1>
					<div className={`row2 ${styles.carDetails__info__specs}`}>
						{cars.map((car) => {
							return <CarSpec {...car} />;
						})}
					</div>
				</div>
				<img src={carImage} alt="" />
			</div>
		</>
	);
};

export { CarDetails };
