import React from "react";
import styles from "./styles.module.scss";
import {
	Heading,
	Paragraph,
	WhyChooseUsCard,
	WhyChooseUsCardProps,
} from "components";
import {
	CompetitiveRates,
	ExperienceDifference,
	ImpeccableService,
	UnrivaledSelection,
} from "asset";

const SectionSix: React.FC = () => {
	const whyChooseUsCards: WhyChooseUsCardProps[] = [
		{
			icon: <UnrivaledSelection />,
			statement: "Unrivaled Selection",
			description:
				"Explore our diverse fleet, each meticulously maintained and designed to exceed your expectations. From the rugged Defender to the luxurious Velar, we have the perfect Land Rover or Range Rover for your Lagos adventure.",
		},
		{
			icon: <ImpeccableService />,
			statement: "Impeccable Service",
			description:
				"Our dedicated team is passionate about exceeding your expectations. We offer personalized attention, seamless service, and 24/7 support to ensure your rental experience is effortless and enjoyable.",
		},
		{
			icon: <ExperienceDifference />,
			statement: "Experience the Difference",
			description:
				"From airport pickup to convenient drop-off, we go the extra mile to ensure your Lagos experience is truly exceptional. Choose Satin Rides and discover the difference.",
		},
		{
			icon: <CompetitiveRates />,
			statement: "Competitive Rates",
			description:
				"Enjoy competitive rates without compromising on quality or service. We believe luxury shouldn't break the bank, and we offer flexible rental options to suit your budget.",
		},
	];
	return (
		<section className={styles.sectionSix}>
			<div className={`row2 ${styles.sectionSix__top}`}>
				<Heading
					heading="Why choose"
					greenText="us?"
					color="black"
					className={styles.sectionSix__top__heading}
				/>
				<Paragraph
					paragraph="All SATIN vehicles are standard since we want nothing but the best service satisfaction for our customers. Needless to say that we have prepared in earnest to make this achievable."
					color="black"
					className={styles.sectionSix__top__paragraph}
				/>
			</div>

			<div className={`${styles.sectionSix__bottom}`}>
				{whyChooseUsCards.map((card) => {
					return (
						<WhyChooseUsCard {...card} className={styles.whyChooseUsCard} />
					);
				})}
			</div>
		</section>
	);
};

export { SectionSix };
