import { logPageView } from "analytics";
import React, { useEffect } from "react";
import ReactGA from "react-ga";
import { LandingPage } from "./pages";

const App = () => {
	const gaEnv = process.env.gs;
	useEffect(() => {
		if (gaEnv) {
			ReactGA.initialize(gaEnv);
		}
		// Make the GA-Tracking-ID secret. Put in an .env
		logPageView();
	}, []);
	return <LandingPage />;
};
export default App;
