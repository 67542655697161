import React from "react";
import styles from "./styles.module.scss";
import { Paragraph } from "components/paragraph";

export interface ReviewQuoteProps {
	quote: string;
    name: string;
    className?: string;
}

const ReviewQuote: React.FC<ReviewQuoteProps> = ({ quote, name, className }) => {
	return (
		<div className={`column0 ${styles.reviewQuote} ${className}`}>
			<Paragraph
				color="black"
				paragraph={`"${quote}"`}
				className={styles.reviewQuote__quote}
			/>
			<h1 className={styles.reviewQuote__name}> - {name}</h1>
		</div>
	);
};

export { ReviewQuote };
