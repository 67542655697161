import React from "react";
import styles from "./styles.module.scss";
import {
	BookRideStepsCard,
	BookRideStepsCardProps,
	Heading,
	Paragraph,
} from "components";
import {
	BookRover,
	DottedLineBent,
	DottedLineStraight,
	EnjoyTrip,
	MakePayment,
	MobileDottedLineBent,
	PickupRover,
	VerticalDottedLine,
} from "asset";

const SectionSeven: React.FC = () => {
	const BookRideSteps: BookRideStepsCardProps[] = [
		{
			icon: <BookRover />,
			step: "1. Book Your Land Rover",
			description:
				"Make an order for your Land Rover and select your preferences.",
		},
		{
			icon: <MakePayment />,
			step: "2. make your payment",
			description:
				"Make payment for your booking after confirming your preferences",
		},
		{
			icon: <PickupRover />,
			step: "3. Pick-up Land Rover",
			description: "Get ready to hit the road by picking from our location",
		},
		{
			icon: <EnjoyTrip />,
			step: "4. Enjoy Your Road trip",
			description: "Hit the road and enjoy maximum comfort.",
		},
	];
	return (
		<section className={`column2 ${styles.sectionSeven}`}>
			<div className={`row2 ${styles.sectionSeven__top}`}>
				<Paragraph
					paragraph="Feeling convinced? Want to try us out? Curious about how the booking process works? Here’s how to book a Satin ride in four easy steps!"
					color="black"
					className={styles.sectionSeven__top__paragraph}
				/>
				<Heading
					heading="book a ride"
					greenText="today!"
					color="black"
					className={styles.sectionSeven__top__heading}
				/>
			</div>
			<div className={`column ${styles.sectionSeven__steps}`}>
				<div className={`row2 ${styles.sectionSeven__steps__top}`}>
					<BookRideStepsCard {...BookRideSteps[0]} />
					<div className={styles.showDesktop}>
						<DottedLineStraight />
					</div>
					<div className={styles.showMobile}>
						<VerticalDottedLine />
					</div>
					<BookRideStepsCard {...BookRideSteps[1]} />
				</div>
				<div className={styles.sectionSeven__steps__dottedCurvedDesktop}>
					<DottedLineBent />
				</div>
				<div className={styles.sectionSeven__steps__dottedCurvedMobile}>
					<MobileDottedLineBent />
				</div>
				<div className={`row2 ${styles.sectionSeven__steps__bottom}`}>
					<BookRideStepsCard {...BookRideSteps[2]} />
					<div className={styles.showDesktop}>
						<DottedLineStraight />
					</div>
					<div className={styles.showMobile}>
						<VerticalDottedLine />
					</div>
					<BookRideStepsCard {...BookRideSteps[3]} />
				</div>
			</div>
		</section>
	);
};

export { SectionSeven };
