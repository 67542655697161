import React from "react";
import styles from "./styles.module.scss";
import { BookingButtons, Heading, Navbar, Paragraph, Tag } from "components";

const HeroSection: React.FC = () => {
	return (
		<>
			<section className={styles.heroDiv}>
				<Navbar />
				<div className={`column0 ${styles.heroDiv__content}`}>
					<Tag />
					<Heading
						heading={"rent luxurious cars in lagos..."}
						color="white"
						greenText={"fast and easy"}
						className={styles.heroDiv__content__heading}
					/>
					<Paragraph
						color={"white"}
						paragraph={
							"Welcome to the best luxury car rental service in Lagos, Nigeria. Just at your finger tips, you can get the right car to fit your specific need, desire and budget."
						}
						className={styles.heroDiv__content__paragraph}
					/>
					<BookingButtons />
				</div>
			</section>
		</>
	);
};

export { HeroSection };
