import React from "react";
import styles from "./styles.module.scss";
import { IconBackground, Paragraph } from "components";

export interface WhyChooseUsCardProps {
	icon: any;
	statement: string;
	description: string;
	className?: string;
}

const WhyChooseUsCard: React.FC<WhyChooseUsCardProps> = ({
	icon,
	statement,
	description,
	className,
}) => {
	return (
		<>
			<div className={`column0 ${styles.whyChooseUsCard} ${className}`}>
				<IconBackground icon={icon} size="80px" backgroundColor={"#e7e6e6"} />
				<h1 className={styles.whyChooseUsCard__statement}>{statement}</h1>
				<Paragraph color={"black"} paragraph={description} />
			</div>
		</>
	);
};

export { WhyChooseUsCard };
