import React from "react";
import styles from "./styles.module.scss";

interface IconBackgroundProp {
	icon: any;
	size: string;
	backgroundColor: string;
}

const IconBackground: React.FC<IconBackgroundProp> = ({
	icon,
	size,
	backgroundColor,
}) => {
	return (
		<div
			className={`row ${styles.iconBackground}`}
			style={{ width: size, height: size, backgroundColor: backgroundColor }}
		>
			{icon}
		</div>
	);
};

export { IconBackground };
