import React from "react";
import styles from "./styles.module.scss";

interface HeadingProps {
	heading: string;
	greenText?: string;
	width?: string;
	color: "white" | "black";
	className?: string;
}

const Heading: React.FC<HeadingProps> = ({
	heading,
	greenText,
	width,
	color,
	className,
}) => {
	return (
		<>
			<h1
				className={`${className} ${styles.heading}`}
				style={{
					width: width,
					color: color === "white" ? "#F3F9EC" : "#0D0907",
				}}
			>
				{heading} <span>{greenText}</span>
			</h1>
		</>
	);
};

export { Heading };
