import React from "react";
import styles from "./styles.module.scss";
import { IconBackground } from "components/iconBackground";

export interface FeatureCardProps {
	icon: any;
	featureStatement: string;
}

const FeaturesCard: React.FC<FeatureCardProps> = ({
	icon,
	featureStatement,
}) => {
	return (
		<>
			<div className={`row2 ${styles.featureCard}`}>
				<IconBackground icon={icon} size="120px" backgroundColor={"#e7e6e6"} />
				<h1 className={styles.featureCard__statement}>{featureStatement}</h1>
			</div>
		</>
	);
};

export { FeaturesCard };
