import React, { useState } from "react";
import styles from "./styles.module.scss";
import { HeroLogo } from "asset";
import { Button } from "components";

interface NavbarProps {
	className?: string;
}

const Navbar: React.FC<NavbarProps> = ({ className }) => {
	const [openMenu, setOpenMenu] = useState(false);
	const [toggle, setToggle] = useState(false);

	const handleToggle = () => {
		setToggle(!toggle);
		setOpenMenu(!openMenu);
	};
	const [activeNav, setActive] = useState({
		home: false,
		about: false,
		services: false,
	});
	const homeNav = activeNav.home ? `${styles.activeNav}` : "";
	const aboutNav = activeNav.about ? `${styles.activeNav}` : "";
	const servicesNav = activeNav.services ? `${styles.activeNav}` : "";
	return (
		<>
			<section className={`${styles.navbar} ${className} row2`}>
				<HeroLogo />
				<div className={styles.navbar__partitionLine}></div>
				<nav className={`row2 ${styles.navbar__navigation}`}>
					<a
						href="/"
						onClick={() => {
							setActive({
								home: true,
								about: false,
								services: false,
							});
						}}
						className={homeNav}
					>
						Home
					</a>
					<a
						href="#aboutUs"
						onClick={() => {
							setActive({
								home: false,
								about: true,
								services: false,
							});
						}}
						className={aboutNav}
					>
						About Us
					</a>
					<a
						href="/"
						onClick={() => {
							setActive({
								home: false,
								about: false,
								services: true,
							});
						}}
						className={servicesNav}
					>
						Services
					</a>
				</nav>
				<div className={styles.navbar__partitionLine}> </div>
				<Button type={"fill"} onClick={() => {}}>
					Book Now
				</Button>
			</section>
			<section className={`row2 ${styles.navbarMobile} ${className}`}>
				<HeroLogo />

				<div className={styles.navbarMobile__partitionLine}></div>
				<div onClick={handleToggle} className={`row ${styles.button}`}>
					<div
						className={
							toggle
								? `${styles.container} ${styles.change}`
								: `${styles.container}`
						}
						onClick={handleToggle}
					>
						<div className={styles.bar1}></div>
						<div className={styles.bar2}></div>
						<div className={styles.bar3}></div>
					</div>
				</div>
			</section>
			{toggle ? (
				<nav className={`row2 ${styles.navbarMobile__navigation}`}>
					<a
						href="/"
						onClick={() => {
							setActive({
								home: true,
								about: false,
								services: false,
							});
						}}
						className={homeNav}
					>
						Home
					</a>
					<a
						href="#aboutUs"
						onClick={() => {
							setActive({
								home: false,
								about: true,
								services: false,
							});
						}}
						className={aboutNav}
					>
						About Us
					</a>
					<a
						href="/"
						onClick={() => {
							setActive({
								home: false,
								about: false,
								services: true,
							});
						}}
						className={servicesNav}
					>
						Services
					</a>
				</nav>
			) : (
				""
			)}
		</>
	);
};

export { Navbar };
