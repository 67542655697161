import React from "react";
import styles from "./styles.module.scss";

export interface MetricTemplateProps {
	value: string;
	icon: any;
	benchmark: string;
	symbol: string;
}

const MetricTemplate: React.FC<MetricTemplateProps> = ({
	value,
	icon,
	benchmark,
	symbol
}) => {
	return (
		<>
			<div className={styles.metricTemplate}>
				<p className={styles.metricTemplate__value}>
					{value}
					<span>{symbol}</span>
				</p>
				<div className={`row ${styles.metricTemplate__bottom}`}>
					{icon}
					<p>{benchmark}</p>
				</div>
			</div>
		</>
	);
};

export { MetricTemplate };
