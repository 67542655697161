import React from "react";
import styles from "./styles.module.scss";
import { Heading, Paragraph } from "components";
import {
	FooterFacebook,
	FooterHalatech,
	FooterInstagram,
	FooterTwitter,
	FooterWhatsapp,
	SatinFooter,
} from "asset";

const FooterSection: React.FC = () => {
	return (
		<section className={`column0 ${styles.footerSection}`}>
			<div className={`row2 ${styles.footerSection__top}`}>
				<Heading
					className={styles.footerSection__top__heading}
					heading={"explore lagos"}
					color={"black"}
				/>
				<Paragraph
					className={styles.footerSection__top__paragraph}
					color={"black"}
					paragraph={
						"Contact Satin Rides today and discover the difference of renting a Land Rover or Range Rover from the experts."
					}
				/>
				<nav className={`row2 ${styles.footerSection__top__navigation}`}>
					<a href="">Home</a>
					<a href="">About Us</a>
					<a href="">Book Now</a>
				</nav>
			</div>
			<div className={`row2 ${styles.footerSection__logo}`}>
				<div className={`row2 ${styles.footerSection__logo__socials}`}>
					<FooterFacebook />
					<FooterInstagram />
					<FooterTwitter />
					<FooterWhatsapp />
				</div>
				<div className={`${styles.footerSection__logo__satin}`}>
					<img src={SatinFooter} alt="" />
				</div>
			</div>
			<div className={styles.footerSection__horizontalLine}></div>
			<div className={`row ${styles.footerSection__bottom}`}>
				<Paragraph
					className={styles.footerSection__bottom__paragraph}
					color={"black"}
					paragraph={"powered by halaTech.aI"}
				/>
				<FooterHalatech />
			</div>
		</section>
	);
};

export { FooterSection };
