import React, { useState } from "react";
import styles from "./styles.module.scss";
import { CarDetails, CarDetailsProps, Heading } from "components";
import { Car2 } from "asset";

const SectionTwo: React.FC = () => {
	const [active, setActive] = useState({
		rangeRover: true,
		landRover: false,
	});
	const rangeRoverActive = () => {
		setActive({
			rangeRover: true,
			landRover: false,
		});
	};
	const landRoverActive = () => {
		setActive({
			rangeRover: false,
			landRover: true,
		});
	};
	const cars: CarDetailsProps[] = [
		{
			carColor: "matte black",
			carImage: Car2,
			carName: "2012 range rover sport",
			driverLevel: "pro",
			vehicleType: "suv",
			driveType: "AWD",
			wifi: "WI-FI",
		},
		{
			carColor: "matte black",
			carImage: Car2,
			carName: "2012 range rover sport",
			driverLevel: "pro",
			vehicleType: "suv",
			driveType: "AWD",
			wifi: "WI-FI",
		},
	];
	return (
		<section className={styles.sectionTwo}>
			<div className={styles.sectionTwo__top}>
				<Heading heading={"Our fleet"} color={"white"} />
			</div>
			<section className={styles.sectionTwo__fleet}>
				<ul className={`row0 ${styles.sectionTwo__fleet__list}`}>
					<li
						className={styles.sectionTwo__fleet__list__car}
						onClick={() => rangeRoverActive()}
						style={
							active.rangeRover
								? { color: "#8ac440", borderBottom: "1px solid #8ac440" }
								: { color: "#767676", borderBottom: "1px solid #767676" }
						}
					>
						<span>Range Rover</span>
					</li>
					<li
						className={styles.sectionTwo__fleet__list__car}
						onClick={() => landRoverActive()}
						style={
							active.landRover
								? { color: "#8ac440", borderBottom: "1px solid #8ac440" }
								: { color: "#767676", borderBottom: "1px solid #767676" }
						}
					>
						<span>Land Rover</span>
					</li>
				</ul>

				{active.rangeRover && <CarDetails {...cars[0]} />}
				{active.landRover && <CarDetails {...cars[1]} />}
			</section>
		</section>
	);
};

export { SectionTwo };
