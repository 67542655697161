import React from "react";
import styles from "./styles.module.scss";
import { IconBackground, Paragraph } from "components";

export interface BookRideStepsCardProps {
	icon: any;
	step: string;
	description: string;
	className?: string;
}

const BookRideStepsCard: React.FC<BookRideStepsCardProps> = ({
	icon,
	step,
	description,
	className,
}) => {
	return (
		<div className={`column ${styles.bookRideCard} ${className}`}>
			<IconBackground icon={icon} size="80px" backgroundColor={"#EDF6E2"} />
			<h1 className={styles.bookRideCard__step}>{step}</h1>
			<Paragraph
				color={"black"}
				paragraph={description}
				className={styles.bookRideCard__description}
			/>
		</div>
	);
};

export { BookRideStepsCard };
