import React from "react";
import styles from "./styles.module.scss";
import { BookingButtons, Heading, Paragraph } from "components";
import { Car4 } from "asset";

const SectionThree: React.FC = () => {
	return (
		<section className={styles.sectionThree}>
			<div className={`row2 ${styles.sectionThree__top}`}>
				<div className={`column0 ${styles.sectionThree__top__left}`}>
					<Paragraph
						color={"white"}
						paragraph={
							"All SATIN vehicles are standard since we want nothing but the best service satisfaction for our customers. Occasionally, situations also arise when you need not just a car, but a premium status car."
						}
						className={styles.sectionThree__top__left__paragraph}
					/>
					<BookingButtons />
				</div>
				<Heading
					heading={"conflicted? Book Satin "}
					greenText={"Today"}
					color={"white"}
					className={styles.sectionThree__top__heading}
				/>
			</div>
			<img src={Car4} alt="" />
		</section>
	);
};

export { SectionThree };
