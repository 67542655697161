import React from "react";
import styles from "./styles.module.scss";
import { FeatureCardProps, FeaturesCard, Heading, Paragraph } from "components";
import {
	LuxuriousVehicles,
	CustomerSupport,
	ProfessionalDrivers,
	Car5,
} from "asset";

const SectionFour = () => {
	const features: FeatureCardProps[] = [
		{
			icon: <LuxuriousVehicles />,
			featureStatement: "Luxurious Land Rover Vehicles",
		},
		{
			icon: <ProfessionalDrivers />,
			featureStatement: "Professional And Courteous Drivers",
		},
		{
			icon: <CustomerSupport />,
			featureStatement: "Priority Customer Support",
		},
	];
	return (
		<section className={`column0 ${styles.sectionFour}`}>
			<div className={styles.sectionFour__top}>
				<Heading
					heading={"our"}
					greenText={"features"}
					color={"black"}
					className={styles.sectionFour__top__heading}
				/>
				<Paragraph
					color={"black"}
					paragraph={
						"In a bid to ensure your well-being and comfortability while using our service, all of our vehicles have the following characteristics"
					}
					className={styles.sectionFour__top__paragraph}
				/>
			</div>
			<div className={`row0 ${styles.sectionFour__bottom}`}>
				<div className={`column0 ${styles.sectionFour__bottom__left}`}>
					{features.map((feature) => {
						return <FeaturesCard {...feature} />;
					})}
				</div>
				<div className={`row1 ${styles.sectionFour__bottom__right}`}>
					<img src={Car5} alt="" />
				</div>
			</div>
		</section>
	);
};

export { SectionFour };
