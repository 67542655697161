import * as React from "react";
import styles from "./styles.module.scss";

interface ButtonProps {
	children: any;
	className?: string;
	type: "fill" | "whiteOutline" | "blackOutline";
	onClick: (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
	disabled?: boolean;
}

const Button: React.FC<ButtonProps> = ({
	children,
	type,
	className,
	onClick,
	disabled,
}) => {
	return (
		<button
			disabled={disabled}
			onClick={(e) => {
				e.preventDefault();
				onClick(e);
			}}
			className={`${styles.btn} ${styles[type]} ${className}`}
		>
			{children}
		</button>
	);
};

export { Button };
