import React from "react";
import styles from "./styles.module.scss";
import { Paragraph } from "components/paragraph";

export interface CarSpecProps {
	value: string;
	spec: string;
}

const CarSpec: React.FC<CarSpecProps> = ({ value, spec }) => {
	return (
		<div className={styles.specCard}>
			<h1 className={styles.specCard__value}>{value}</h1>
			<Paragraph
				paragraph={spec}
				color="white"
				className={styles.specCard__spec}
			/>
		</div>
	);
};

export { CarSpec };
