import React from "react";
import styles from "./styles.module.scss";
import { Faqs, Heading, Paragraph } from "components";
import { FaqImage } from "asset";

const SectionNine: React.FC = () => {
	return (
		<section className={styles.sectionNine}>
			<div className={`row2 ${styles.sectionNine__top}`}>
				<Heading
					heading={"frequently asked "}
					greenText={"questions"}
					color={"black"}
					className={styles.sectionNine__top__heading}
				/>
				<Paragraph
					color={"black"}
					paragraph={
						"Here’s what folks, or better still, our esteemed clients have to say about our service."
					}
					className={styles.sectionNine__top__paragraph}
				/>
			</div>
			<div className={`row2 ${styles.sectionNine__bottom}`}>
				<div className={styles.sectionNine__bottom__imageContainer}>
					<img src={FaqImage} alt="" />
				</div>
				<Faqs />
			</div>
		</section>
	);
};

export { SectionNine };
