import React from "react";
import styles from "./styles.module.scss";
import {
	BookingButtons,
	Heading,
	MetricTemplate,
	MetricTemplateProps,
	Paragraph,
} from "components";
import { Car6, HappyClients, SatisfactoryRate } from "asset";

const SectionFive: React.FC = () => {
	const metrics: MetricTemplateProps[] = [
		{
			value: "2000",
			icon: <HappyClients />,
			benchmark: "Happy clients",
			symbol: "+",
		},
		{
			value: "100",
			icon: <SatisfactoryRate />,
			benchmark: "satisfactory rate",
			symbol: "%",
		},
	];
	return (
		<section className={`column0 ${styles.sectionFive}`} id="aboutUs">
			<p className={styles.about}>About us</p>
			<Heading
				heading={"satin car service:"}
				greenText={"bts"}
				color={"black"}
				className={styles.sectionFive__heading}
			/>
			<div className={`row2 ${styles.sectionFive__bottom}`}>
				<div className={styles.sectionFive__bottom__left}>
					<img src={Car6} alt="" />
				</div>
				<div className={`column0 ${styles.sectionFive__bottom__right}`}>
					<Paragraph
						color={"black"}
						paragraph={
							"Satin is dedicated to delivering exceptional car hire experiences for the discerning business traveler, ex-pat, and luxury tourist in Lagos. We believe that premium VIP car service should be accessible and affordable, and that's why we offer the finest vehicles at an exceptional value. Our fleet consists exclusively of Land Rover vehicles, ensuring that each and every ride is a luxurious and practical experience."
						}
						className={styles.sectionFive__bottom__right__paragraph}
					/>
					<div className={`row0 ${styles.sectionFive__bottom__right__metric}`}>
						{metrics.map((metric) => {
							return <MetricTemplate {...metric} />;
						})}
					</div>
					<BookingButtons
						dark
						className={styles.sectionFive__bottom__right__bookingButtons}
					/>
				</div>
			</div>
		</section>
	);
};

export { SectionFive };
