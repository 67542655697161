import React from "react";
import styles from "./styles.module.scss";

interface ParagraphProps {
	color: "white" | "black";
	paragraph: string;
	width?: string;
	className?: string;
}

const Paragraph: React.FC<ParagraphProps> = ({
	className,
	color,
	paragraph,
	width,
}) => {
	return (
		<>
			<p
				className={`${className} ${styles.paragraph}`}
				style={{
					color: color === "white" ? "#f3f9ec" : "#5D5A59",
					width: width,
				}}
			>
				{paragraph}
			</p>
		</>
	);
};

export { Paragraph };
