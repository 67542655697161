import React from "react";
import styles from "./styles.module.scss";
import {
	HeroSection,
	SectionOne,
	SectionTwo,
	SectionThree,
	SectionFour,
	SectionFive,
	SectionSix,
	SectionSeven,
	SectionEight,
	SectionNine,
	SectionTen,
	FooterSection,
} from "features";

const LandingPage = () => {
	return (
		<>
			<HeroSection />
			<SectionOne />
			<SectionTwo />
			<SectionThree />
			<SectionFour />
			<SectionFive />
			<SectionSix />
			<SectionSeven />
			<SectionEight />
			<SectionNine />
			<SectionTen />
			<FooterSection />
		</>
	);
};

export { LandingPage };
