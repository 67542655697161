import React from "react";
import styles from "./styles.module.scss";
import { BookingButtons, Button, Heading, Paragraph } from "components";
import { Car7 } from "asset";

const SectionTen: React.FC = () => {
	return (
		<section className={styles.sectionTen}>
			<div className={`row2 ${styles.sectionTen__top}`}>
				<Heading
					heading={"ready to elevate your"}
					greenText={"journey?"}
					color={"black"}
					className={styles.sectionTen__top__heading}
				/>
				<div className={`column0 ${styles.sectionTen__top__right}`}>
					<Paragraph
						color={"black"}
						paragraph={
							"Uncover hidden gems, discover vibrant neighborhoods, and experience the best of Lagos with our curated guide. From bustling markets to serene beaches, we'll help you create an unforgettable itinerary."
						}
						className={styles.sectionTen__top__right__paragraph}
					/>
					<BookingButtons dark />
				</div>
			</div>
			<img src={Car7} alt="" />
		</section>
	);
};

export { SectionTen };
