import React from "react";
import styles from "./styles.module.scss";
import { Button, Heading, Paragraph } from "components";
import { Car1 } from "asset";

const SectionOne = () => {
	return (
		<section className={styles.sectionOne}>
			<div className={`row2 ${styles.sectionOne__top}`}>
				<Heading
					heading={"vehicles that speak luxury... a language you "}
					greenText={"understand"}
					color={"white"}
					className={styles.sectionOne__top__heading}
				/>
				<div className={`row ${styles.sectionOne__top__paragraph}`}>
					<Paragraph
						color={"white"}
						paragraph={
							"Quality And Road-Worthy Vehicles That Represent Your Bold Personality..."
						}
						className={styles.sectionOne__top__paragraph__p}
					/>
				</div>
			</div>
			<div className={styles.car}>
				<img src={Car1} alt="" />
				<div></div>
			</div>
			<div className={styles.requestRideModalContainer}>
				<div
					className={`column0 ${styles.requestRideModalContainer__requestRideModal}`}
				>
					<div
						className={styles.requestRideModalContainer__requestRideModal__top}
					>
						<h3>Request a ride now</h3>
					</div>
					<div
						className={`row0 ${styles.requestRideModalContainer__requestRideModal__mid}`}
					>
						<form
							action=""
							className={`row2 ${styles.requestRideModalContainer__requestRideModal__mid__form}`}
						>
							<div
								className={`${styles.requestRideModalContainer__requestRideModal__mid__form__pickUp} column0`}
							>
								<label htmlFor="pickUp">pick up location</label>
								<input
									name="pickUp"
									id="pickUp"
									type="text"
									placeholder="enter your pick up location"
									// value={checkDetails.pickUp}
									onChange={(e) =>
										// setCheckDetails((prev) => {
										// 	return { ...prev,pickUp: e.target.value };
										// })
										{}
									}
									required
								/>
							</div>

							<div
								className={`${styles.requestRideModalContainer__requestRideModal__mid__form__dropOff} column0`}
							>
								<label htmlFor="dropOff">drop location</label>
								<input
									name="dropOff"
									id="dropOff"
									type="text"
									placeholder="enter your Drop Off location"
									// value={checkDetails.dropOff}
									onChange={
										(e) => {}
										// setCheckDetails((prev) => {
										// 	return { ...prev, dropOff: e.target.value };
										// })
									}
									required
								/>
							</div>
						</form>
						<Button
							type={"fill"}
							onClick={() => {}}
							className={styles.bookRideButton}
						>
							book your ride now
						</Button>
					</div>
					<div
						className={`row0 ${styles.requestRideModalContainer__requestRideModal__bottom}`}
					>
						<a href="/">schedule for later</a>
					</div>
				</div>
			</div>
		</section>
	);
};

export { SectionOne };
