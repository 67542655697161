import React from "react";
import styles from "./styles.module.scss";
import {
	Heading,
	Paragraph,
	Button,
	ReviewQuoteProps,
	ReviewQuote,
} from "components";
import { LagosDrone } from "asset";

const SectionEight: React.FC = () => {
	const reviews: ReviewQuoteProps[] = [
		{
			quote:
				"Satin Rides took our Lagos trip to the next level. The land Rover was perfect for exploring the hidden beaches, and the service was impeccable. We'll definitely be back!",
			name: "Sarah ADEKUNMI",
		},
		{
			quote:
				"The Range Rover Sport was the perfect way to make a statement at our business meeting. It exudes luxury and professionalism, and the team at Satin Rides made everything easy.",
			name: "Francis obi",
		},
		{
			quote:
				"The Range Rover Sport was the perfect way to make a statement at our business meeting. It exudes luxury and professionalism, and the team at Satin Rides made everything easy.",
			name: "Francis obi",
		},
	];
	return (
		<section className={` ${styles.sectionEight}`}>
			<div className={`row2 ${styles.sectionEight__top}`}>
				<Heading
					heading={"WORD ON THE STREETS OF "}
					greenText={"LAGOS IS..."}
					color={"black"}
					className={styles.sectionEight__top__heading}
				/>
				<Paragraph
					color={"black"}
					paragraph={
						"Here’s what folks, or better still, our esteemed clients have to say about our service."
					}
					className={styles.sectionEight__top__paragraph}
				/>
			</div>
			<div className={`row2 ${styles.sectionEight__bottom}`}>
				<div className={`column0 ${styles.sectionEight__bottom__reviewQuotes}`}>
					{reviews.map((review) => {
						return <ReviewQuote {...review} />;
					})}
				</div>
				<div className={styles.sectionEight__bottom__imageContainer}>
					<img src={LagosDrone} alt="" />
				</div>
			</div>
		</section>
	);
};

export { SectionEight };
